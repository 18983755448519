<!--  -->
<template>
  <div class='action'>操作手册</div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      
    };
  },
  computed: {},
  watch: {},
  created() {
    
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    
  },
}
</script>

<style lang='less' scoped>

</style>